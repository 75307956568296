.product-item {
    @apply hover:shadow-lg shadow duration-300 gap-4 flex-col h-full p-4 rounded-lg bg-gray-50 overflow-hidden ;
    span {
     @apply text-xl lg:text-2xl font-semibold opacity-50;
    }
 }
 
 .product-list a.active{
     transition: all 0.5s ease;
 }
 
 .product-list a.delete{
     display: none;
     transition: all 0.5s ease;
 }
 
 .product-image{
     @apply h-full object-contain mix-blend-darken;
 }
 
 .product-content{
     @apply flex w-full h-full items-center justify-center overflow-hidden
 }