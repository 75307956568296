.hero-box {
    @apply flex w-full items-center justify-center lg:justify-center; 
    img{
        @apply h-12 lg:h-24;
    }
    img.bicak {
        @apply scale-125;
    }
    span {
        @apply text-base lg:text-xl font-bold;
    }
   }


 