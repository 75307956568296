@import "./tailwind.css";
@import "./elements/headers.css";
@import "./elements/hero.css";
@import "./pages/products.css";
@import "./elements/button.css";
@import "./elements/menu.css";

:root {
    @apply font-[Nunito];
}
.header-menu a {
    @apply text-gray-600;
    &.active {
        @apply text-red-600
    }
}
/* Harita kapsayıcısı için stil */
.leaflet-bottom {
    scale: 0!important;
}

.slick-arrow {
    z-index: 10;
    @apply w-4 h-4;
    @apply font-[Nunito];
    &::before {
        font-size: 1rem;
        color: #fff;
        @apply p-4;
    }
}

