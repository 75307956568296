.main-menu {
    height: calc(100vh - 4rem);
    @apply 
    /* Genel Stil */
    flex-col
    items-center
    justify-start
    py-0
    gap-2
    px-4
    fixed
    hidden
    z-50
    w-full
    top-24
    left-0
    
    bg-gray-50
     /* Desktop */
     lg:w-auto
     lg:flex
     lg:flex-row
     lg:gap-4
     lg:visible
     lg:h-auto
     lg:static
     lg:bg-transparent
     lg:py-4
     lg:px-0;
     &.active {
        @apply flex
     }
}

.main-menu-item {

        @apply 
        /* Genel Stil */
        w-full
        h-12
        text-2xl
        border-b
        border-b-gray-600
        px-2 py-2
        font-semibold
        hover:text-red-500
        duration-300
        active:text-red-700
        /* Desktop */
        lg:h-auto
        lg:w-auto
        lg:text-base
        lg:border-b-0
        lg:border-b-transparent
        ;
        &.active {
            @apply text-red-800;
        }
        
}

.menu-toggler-button{
    @apply absolute top-10 right-20 px-3 py-2 cursor-pointer bg-red-600 text-red-50 rounded lg:hidden;
}